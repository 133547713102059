import { Selection, SelectionStatus } from '../../API';

export const mockSelections: Selection[] = [
  {
    id: 'selectionid1',
    userID: 'userid1',
    taskID: 'taskid1',
    status: SelectionStatus.STARTED,
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Selection'
  },
  {
    id: 'selectionid2',
    userID: 'userid2',
    taskID: 'taskid2',
    status: SelectionStatus.STARTED,
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Selection'
  },
  {
    id: 'selectionid3',
    userID: 'userid1',
    taskID: 'taskid3',
    status: SelectionStatus.FINISHED,
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Selection'
  },
  {
    id: 'selectionid4',
    userID: 'userid3',
    taskID: 'taskid1',
    status: SelectionStatus.FINISHED,
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Selection'
  },
  {
    id: 'selectionid5',
    userID: 'userid2',
    taskID: 'taskid3',
    status: SelectionStatus.FINISHED,
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Selection'
  }
];
