import { AmplifyUser } from '@aws-amplify/ui';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { GetUser } from '../queries/user';
import { User } from '../API';

export interface UserAttributes {
  id: string;
  email: string;
}

export interface AuthContextType {
  userAttributes: UserAttributes | null;
  saveUserAttributes: (targetUserAttributes: UserAttributes | null) => void;
  userDetails: User | null;
  saveUserDetails: (targetUserDetails: User | null) => void;
  authStatus: string;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const { user, authStatus } = useAuthenticator((context) => [context.user]);

  const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(
    null
  );
  const saveUserAttributes = (targetUserAttributes: UserAttributes | null) =>
    setUserAttributes(targetUserAttributes);
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const saveUserDetails = (targetUserDetails: User | null) =>
    setUserDetails(targetUserDetails);

  const fetchUserAndUpdateState = (user: AmplifyUser) => {
    if (user?.attributes?.sub) {
      const attributes = user?.attributes;
      GetUser(attributes.sub || '')
        .then((details) => {
          setUserAttributes({
            id: attributes.sub,
            email: attributes.email
          });
          setUserDetails(details);
        })
        .catch(() => {
          setUserAttributes(null);
          setUserDetails(null);
        });
    } else {
      setUserAttributes(null);
      setUserDetails(null);
    }
  };

  useEffect(() => {
    fetchUserAndUpdateState(user);
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        userAttributes,
        saveUserAttributes,
        userDetails,
        saveUserDetails,
        authStatus
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useCurrentAuth = () => {
  const currentAuthContext = useContext(AuthContext);

  if (!currentAuthContext) {
    throw new Error('useCurrentAuth has to be used within <AuthProvider>');
  }

  return currentAuthContext;
};
