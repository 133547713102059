import { API, Storage, graphqlOperation } from 'aws-amplify';
import { createUser, deleteUser, updateUser } from '../graphql/mutations';
import {
  CreateUserInput,
  CreateUserMutation,
  DeleteUserMutation,
  GetUserQuery,
  ListUsersQuery,
  UpdateUserMutation
} from '../API';
import { GraphQLQuery } from '@aws-amplify/api';
import { getUser, listUsers } from '../graphql/queries';
import { DEVELOPMENT, ENVIRONMENT } from '../types/environment';
import { mockUsers } from '../types/mock/users';
import { User } from '../API';

//////////////////////////////////////////////////////////////////
// Function name : CreateUser
// Function goal : Create new user
// Input :
// -  Req - email   : string
// -  Opt - name    : string | null (default: null)
// -  Opt - details : string | null (default: null)
// Output : data of created user
//////////////////////////////////////////////////////////////////
export async function CreateUser(
  email: string,
  name: string | null = null,
  details: string | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Creating new user');
    return;
  }
  const user: CreateUserInput = { name: name, email: email, details: details };
  const newUser: any = await API.graphql<GraphQLQuery<CreateUserMutation>>(
    graphqlOperation(createUser, { input: user })
  );
  console.log('Created new User');
  console.log(newUser.data.createUser);

  return newUser.data.createUser;
}

//////////////////////////////////////////////////////////////////
// Function name : UpdateUser
// Function goal : Update an existing user
// Input :
// -  Req - userId  : string
// -  Opt - name    : string | null (default: null)
// -  Opt - email   : string | null (default: null)
// -  Opt - details : string | null (default: null)
// Output : data of updated user
//////////////////////////////////////////////////////////////////
export async function UpdateUser(
  userId: string,
  name: string | null = null,
  email: string | null = null,
  details: User | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Updating existing user');
    return;
  }
  const updatedUser: any = await API.graphql<GraphQLQuery<UpdateUserMutation>>(
    graphqlOperation(updateUser, {
      input: {
        id: userId,
        name: name,
        email: email,
        details: details ?? JSON.parse('{}')
      }
    })
  );
  console.log('Updated a User');
  console.log(updatedUser.data.updateUser);

  return updatedUser.data.updateUser;
}

//////////////////////////////////////////////////////////////////
// Function name : DeleteUser
// Function goal : Delete an existing user
// Input :
// -  Req - userId  : string
// Output : data of deleted user
//////////////////////////////////////////////////////////////////
export async function DeleteUser(userId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Deleting existing user');
    return;
  }
  const deletedUser: any = await API.graphql<GraphQLQuery<DeleteUserMutation>>(
    graphqlOperation(deleteUser, { input: { id: userId } })
  );
  console.log('Deleted a User');
  console.log(deletedUser.data.deleteUser);

  return deletedUser.data.deleteUser;
}

//////////////////////////////////////////////////////////////////
// Function name : GetAllUsers
// Function goal : List all existing users
// Input : None
// Output : list of existing users
//////////////////////////////////////////////////////////////////
export async function GetAllUsers() {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning all users');
    return mockUsers;
  }
  const allUsers: any = await API.graphql<GraphQLQuery<ListUsersQuery>>(
    graphqlOperation(listUsers)
  );
  console.log('Got all users');
  console.log(allUsers.data.listUsers);

  return allUsers.data.listUsers;
}

//////////////////////////////////////////////////////////////////
// Function name : GetUser
// Function goal : Get an existing user
// Input :
// -  Req - userId  : string
// Output : data of the chosen user
//////////////////////////////////////////////////////////////////
export async function GetUser(userId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning one user');
    return mockUsers[0];
  }
  const oneUser: any = await API.graphql<GraphQLQuery<GetUserQuery>>(
    graphqlOperation(getUser, { id: userId })
  );
  console.log('Got a User');
  console.log(oneUser.data.getUser);

  return oneUser.data.getUser;
}

export async function UpdateUserProfilePicture(
  userId: string,
  profilePicture: any,
  details: User | null = null
) {
  console.log('Uploading profile picture');

  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Not uploading profile picture');
    return;
  }

  const S3Url = await UploadPictureToS3(userId, profilePicture);

  if (S3Url) {
    const updatedUser: any = await API.graphql<
      GraphQLQuery<UpdateUserMutation>
    >(
      graphqlOperation(updateUser, {
        input: {
          id: userId,
          details: {
            profilePictureS3Url: S3Url,
            ...(details ?? JSON.parse('{}'))
          }
        }
      })
    );
    console.log('Updated a User');
    console.log(updatedUser.data.updateUser);

    return updatedUser.data.updateUser;
  }

  return undefined;
}

// Return S3 link
export async function UploadPictureToS3(fileName: string, image: any) {
  console.log('UploadPictureToS3 called');

  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Not uploading picture');
    console.log(fileName);
    return '';
  }

  const result = await Storage.put(fileName, image, {
    contentType: 'image/*'
  });

  return result;
}
