import '@aws-amplify/ui-react/styles.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

import { Route, Routes } from 'react-router-dom';

import { AuthProvider } from '../contexts/AuthContext';

import { ACHIEVEMENTS_PATH, HOME_PATH, USER_PATH } from '../types/routing';

import { TasksProvider } from '../contexts/TasksContext';
import { ProtectedRoute } from '../contexts/routing/ProtectedRoute';
import { FooterMobile } from './components/Footer';
import { HeaderMobile } from './components/Header';
import { Achievements } from './pages/Achievements';
import { HomePage } from './pages/Home';
import { UserDetails } from './pages/UserDetails';

function MobileApp() {
  return (
    <div className="m_App">
      <HeaderMobile></HeaderMobile>

      <div className="m_AppContent">
        <AuthProvider>
          <TasksProvider>
            <Routes>
              <Route
                path={HOME_PATH}
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={ACHIEVEMENTS_PATH}
                element={
                  <ProtectedRoute>
                    <Achievements />
                  </ProtectedRoute>
                }
              />
              <Route
                path={USER_PATH}
                element={
                  <ProtectedRoute>
                    <UserDetails />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </TasksProvider>
        </AuthProvider>
      </div>

      <FooterMobile></FooterMobile>
    </div>
  );
}

export default MobileApp;
