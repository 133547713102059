import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Analytics } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Task } from '../../API';
import { useCurrentTasks } from '../../contexts/TasksContext';
import { TaskDescription } from '../../shared/components/TaskDescription';
import { CATEGORIES_PATH } from '../../types/routing';
import './Colors.css';
import './Home.css';
import { DEVELOPMENT, ENVIRONMENT } from '../../types/environment';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function HomePage() {
  const navigate = useNavigate();

  const { currentTaskId } = useParams();

  useEffect(() => {
    if (currentTaskId) {
      setTaskDescriptionId(currentTaskId);
    }
  }, [currentTaskId]);

  const [taskDescriptionId, setTaskDescriptionId] = useState<string | null>(
    null
  );
  const { tasks } = useCurrentTasks();

  const addNewTask = () => {
    console.log('Recording event click of addTask to Analytics');
    if (ENVIRONMENT !== DEVELOPMENT) {
      Analytics.record({
        name: 'addTaskClick',
        attributes: {
          testAttribute: 'testing'
        }
      });
    }
    navigate(CATEGORIES_PATH);
  };

  const goToTaskDescription = (taskId: string) => {
    console.log(`Open task description, id: ${taskId}`);
    setTaskDescriptionId(taskId);
  };

  const handleClose = () => {
    setTaskDescriptionId(null);
  };

  function renderTask(activeTask: Task, idx: number) {
    return (
      <div
        key={idx}
        className="activeTask"
        onClick={() => {
          goToTaskDescription(activeTask.id);
        }}
      >
        <p>{activeTask.name}</p>
      </div>
    );
  }

  return (
    <div id="Home">
      <div id="toolbar">
        <div id="activeTaskTitle">Active Tasks</div>
        <div id="addTask" onClick={addNewTask}>
          + Add task
        </div>
      </div>

      <div id="activeTaskList">
        {tasks.map((activeTask, idx) => renderTask(activeTask, idx))}
      </div>

      <Dialog
        open={taskDescriptionId !== null}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <TaskDescription
          taskId={taskDescriptionId as string}
          handleClose={handleClose}
        />
      </Dialog>
    </div>
  );
}
