/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateActiveTaskInput = {
  id?: string | null,
  currentTaskID: string,
  _version?: number | null,
};

export type ModelActiveTaskConditionInput = {
  currentTaskID?: ModelIDInput | null,
  and?: Array< ModelActiveTaskConditionInput | null > | null,
  or?: Array< ModelActiveTaskConditionInput | null > | null,
  not?: ModelActiveTaskConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ActiveTask = {
  __typename: "ActiveTask",
  id: string,
  currentTaskID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateActiveTaskInput = {
  id: string,
  currentTaskID?: string | null,
  _version?: number | null,
};

export type DeleteActiveTaskInput = {
  id: string,
  _version?: number | null,
};

export type CreateSelectionInput = {
  id?: string | null,
  status: SelectionStatus,
  timeStart?: string | null,
  timeFinish?: string | null,
  userID: string,
  taskID: string,
  _version?: number | null,
};

export enum SelectionStatus {
  QUEUED = "QUEUED",
  STARTED = "STARTED",
  FINISHED = "FINISHED",
}


export type ModelSelectionConditionInput = {
  status?: ModelSelectionStatusInput | null,
  timeStart?: ModelStringInput | null,
  timeFinish?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  and?: Array< ModelSelectionConditionInput | null > | null,
  or?: Array< ModelSelectionConditionInput | null > | null,
  not?: ModelSelectionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSelectionStatusInput = {
  eq?: SelectionStatus | null,
  ne?: SelectionStatus | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Selection = {
  __typename: "Selection",
  id: string,
  status: SelectionStatus,
  timeStart?: string | null,
  timeFinish?: string | null,
  userID: string,
  taskID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSelectionInput = {
  id: string,
  status?: SelectionStatus | null,
  timeStart?: string | null,
  timeFinish?: string | null,
  userID?: string | null,
  taskID?: string | null,
  _version?: number | null,
};

export type DeleteSelectionInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  _version?: number | null,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  name: string,
  description?: string | null,
  categoryTasks?: ModelTaskCategoryConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTaskCategoryConnection = {
  __typename: "ModelTaskCategoryConnection",
  items:  Array<TaskCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TaskCategory = {
  __typename: "TaskCategory",
  id: string,
  categoryId: string,
  taskId: string,
  category: Category,
  task: Task,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Task = {
  __typename: "Task",
  id: string,
  name: string,
  activeTaskID: string,
  description?: string | null,
  taskSelections?: ModelSelectionConnection | null,
  taskCategories?: ModelTaskCategoryConnection | null,
  duration?: number | null,
  difficulty?: number | null,
  authorID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelSelectionConnection = {
  __typename: "ModelSelectionConnection",
  items:  Array<Selection | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateTaskInput = {
  id?: string | null,
  name: string,
  activeTaskID: string,
  description?: string | null,
  duration?: number | null,
  difficulty?: number | null,
  authorID: string,
  _version?: number | null,
};

export type ModelTaskConditionInput = {
  name?: ModelStringInput | null,
  activeTaskID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  difficulty?: ModelFloatInput | null,
  authorID?: ModelIDInput | null,
  and?: Array< ModelTaskConditionInput | null > | null,
  or?: Array< ModelTaskConditionInput | null > | null,
  not?: ModelTaskConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateTaskInput = {
  id: string,
  name?: string | null,
  activeTaskID?: string | null,
  description?: string | null,
  duration?: number | null,
  difficulty?: number | null,
  authorID?: string | null,
  _version?: number | null,
};

export type DeleteTaskInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  name?: string | null,
  email: string,
  details?: string | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  name?: string | null,
  email: string,
  details?: string | null,
  userSelections?: ModelSelectionConnection | null,
  authorTask?: ModelTaskConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTaskConnection = {
  __typename: "ModelTaskConnection",
  items:  Array<Task | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateUserInput = {
  id: string,
  name?: string | null,
  email?: string | null,
  details?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateTaskCategoryInput = {
  id?: string | null,
  categoryId: string,
  taskId: string,
  _version?: number | null,
};

export type ModelTaskCategoryConditionInput = {
  categoryId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  and?: Array< ModelTaskCategoryConditionInput | null > | null,
  or?: Array< ModelTaskCategoryConditionInput | null > | null,
  not?: ModelTaskCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateTaskCategoryInput = {
  id: string,
  categoryId?: string | null,
  taskId?: string | null,
  _version?: number | null,
};

export type DeleteTaskCategoryInput = {
  id: string,
  _version?: number | null,
};

export type ModelActiveTaskFilterInput = {
  id?: ModelIDInput | null,
  currentTaskID?: ModelIDInput | null,
  and?: Array< ModelActiveTaskFilterInput | null > | null,
  or?: Array< ModelActiveTaskFilterInput | null > | null,
  not?: ModelActiveTaskFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelActiveTaskConnection = {
  __typename: "ModelActiveTaskConnection",
  items:  Array<ActiveTask | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSelectionFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelSelectionStatusInput | null,
  timeStart?: ModelStringInput | null,
  timeFinish?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  taskID?: ModelIDInput | null,
  and?: Array< ModelSelectionFilterInput | null > | null,
  or?: Array< ModelSelectionFilterInput | null > | null,
  not?: ModelSelectionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTaskFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  activeTaskID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  difficulty?: ModelFloatInput | null,
  authorID?: ModelIDInput | null,
  and?: Array< ModelTaskFilterInput | null > | null,
  or?: Array< ModelTaskFilterInput | null > | null,
  not?: ModelTaskFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  email?: ModelStringInput | null,
  details?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTaskCategoryFilterInput = {
  id?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  taskId?: ModelIDInput | null,
  and?: Array< ModelTaskCategoryFilterInput | null > | null,
  or?: Array< ModelTaskCategoryFilterInput | null > | null,
  not?: ModelTaskCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionActiveTaskFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  currentTaskID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionActiveTaskFilterInput | null > | null,
  or?: Array< ModelSubscriptionActiveTaskFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionSelectionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  timeStart?: ModelSubscriptionStringInput | null,
  timeFinish?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  taskID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSelectionFilterInput | null > | null,
  or?: Array< ModelSubscriptionSelectionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTaskFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  activeTaskID?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionIntInput | null,
  difficulty?: ModelSubscriptionFloatInput | null,
  authorID?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionTaskFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  details?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTaskCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  categoryId?: ModelSubscriptionIDInput | null,
  taskId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionTaskCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionTaskCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateActiveTaskMutationVariables = {
  input: CreateActiveTaskInput,
  condition?: ModelActiveTaskConditionInput | null,
};

export type CreateActiveTaskMutation = {
  createActiveTask?:  {
    __typename: "ActiveTask",
    id: string,
    currentTaskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateActiveTaskMutationVariables = {
  input: UpdateActiveTaskInput,
  condition?: ModelActiveTaskConditionInput | null,
};

export type UpdateActiveTaskMutation = {
  updateActiveTask?:  {
    __typename: "ActiveTask",
    id: string,
    currentTaskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteActiveTaskMutationVariables = {
  input: DeleteActiveTaskInput,
  condition?: ModelActiveTaskConditionInput | null,
};

export type DeleteActiveTaskMutation = {
  deleteActiveTask?:  {
    __typename: "ActiveTask",
    id: string,
    currentTaskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSelectionMutationVariables = {
  input: CreateSelectionInput,
  condition?: ModelSelectionConditionInput | null,
};

export type CreateSelectionMutation = {
  createSelection?:  {
    __typename: "Selection",
    id: string,
    status: SelectionStatus,
    timeStart?: string | null,
    timeFinish?: string | null,
    userID: string,
    taskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSelectionMutationVariables = {
  input: UpdateSelectionInput,
  condition?: ModelSelectionConditionInput | null,
};

export type UpdateSelectionMutation = {
  updateSelection?:  {
    __typename: "Selection",
    id: string,
    status: SelectionStatus,
    timeStart?: string | null,
    timeFinish?: string | null,
    userID: string,
    taskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSelectionMutationVariables = {
  input: DeleteSelectionInput,
  condition?: ModelSelectionConditionInput | null,
};

export type DeleteSelectionMutation = {
  deleteSelection?:  {
    __typename: "Selection",
    id: string,
    status: SelectionStatus,
    timeStart?: string | null,
    timeFinish?: string | null,
    userID: string,
    taskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    categoryTasks?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    categoryTasks?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    categoryTasks?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTaskMutationVariables = {
  input: CreateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type CreateTaskMutation = {
  createTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    activeTaskID: string,
    description?: string | null,
    taskSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskCategories?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    duration?: number | null,
    difficulty?: number | null,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTaskMutationVariables = {
  input: UpdateTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    activeTaskID: string,
    description?: string | null,
    taskSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskCategories?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    duration?: number | null,
    difficulty?: number | null,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTaskMutationVariables = {
  input: DeleteTaskInput,
  condition?: ModelTaskConditionInput | null,
};

export type DeleteTaskMutation = {
  deleteTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    activeTaskID: string,
    description?: string | null,
    taskSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskCategories?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    duration?: number | null,
    difficulty?: number | null,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email: string,
    details?: string | null,
    userSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    authorTask?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email: string,
    details?: string | null,
    userSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    authorTask?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email: string,
    details?: string | null,
    userSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    authorTask?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTaskCategoryMutationVariables = {
  input: CreateTaskCategoryInput,
  condition?: ModelTaskCategoryConditionInput | null,
};

export type CreateTaskCategoryMutation = {
  createTaskCategory?:  {
    __typename: "TaskCategory",
    id: string,
    categoryId: string,
    taskId: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTaskCategoryMutationVariables = {
  input: UpdateTaskCategoryInput,
  condition?: ModelTaskCategoryConditionInput | null,
};

export type UpdateTaskCategoryMutation = {
  updateTaskCategory?:  {
    __typename: "TaskCategory",
    id: string,
    categoryId: string,
    taskId: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTaskCategoryMutationVariables = {
  input: DeleteTaskCategoryInput,
  condition?: ModelTaskCategoryConditionInput | null,
};

export type DeleteTaskCategoryMutation = {
  deleteTaskCategory?:  {
    __typename: "TaskCategory",
    id: string,
    categoryId: string,
    taskId: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetActiveTaskQueryVariables = {
  id: string,
};

export type GetActiveTaskQuery = {
  getActiveTask?:  {
    __typename: "ActiveTask",
    id: string,
    currentTaskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListActiveTasksQueryVariables = {
  filter?: ModelActiveTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListActiveTasksQuery = {
  listActiveTasks?:  {
    __typename: "ModelActiveTaskConnection",
    items:  Array< {
      __typename: "ActiveTask",
      id: string,
      currentTaskID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncActiveTasksQueryVariables = {
  filter?: ModelActiveTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncActiveTasksQuery = {
  syncActiveTasks?:  {
    __typename: "ModelActiveTaskConnection",
    items:  Array< {
      __typename: "ActiveTask",
      id: string,
      currentTaskID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSelectionQueryVariables = {
  id: string,
};

export type GetSelectionQuery = {
  getSelection?:  {
    __typename: "Selection",
    id: string,
    status: SelectionStatus,
    timeStart?: string | null,
    timeFinish?: string | null,
    userID: string,
    taskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSelectionsQueryVariables = {
  filter?: ModelSelectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSelectionsQuery = {
  listSelections?:  {
    __typename: "ModelSelectionConnection",
    items:  Array< {
      __typename: "Selection",
      id: string,
      status: SelectionStatus,
      timeStart?: string | null,
      timeFinish?: string | null,
      userID: string,
      taskID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSelectionsQueryVariables = {
  filter?: ModelSelectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSelectionsQuery = {
  syncSelections?:  {
    __typename: "ModelSelectionConnection",
    items:  Array< {
      __typename: "Selection",
      id: string,
      status: SelectionStatus,
      timeStart?: string | null,
      timeFinish?: string | null,
      userID: string,
      taskID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelectionsByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelectionsByUserIDQuery = {
  selectionsByUserID?:  {
    __typename: "ModelSelectionConnection",
    items:  Array< {
      __typename: "Selection",
      id: string,
      status: SelectionStatus,
      timeStart?: string | null,
      timeFinish?: string | null,
      userID: string,
      taskID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SelectionsByTaskIDQueryVariables = {
  taskID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSelectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SelectionsByTaskIDQuery = {
  selectionsByTaskID?:  {
    __typename: "ModelSelectionConnection",
    items:  Array< {
      __typename: "Selection",
      id: string,
      status: SelectionStatus,
      timeStart?: string | null,
      timeFinish?: string | null,
      userID: string,
      taskID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    categoryTasks?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCategoriesQuery = {
  syncCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTaskQueryVariables = {
  id: string,
};

export type GetTaskQuery = {
  getTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    activeTaskID: string,
    description?: string | null,
    taskSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskCategories?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    duration?: number | null,
    difficulty?: number | null,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTasksQuery = {
  listTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTasksQueryVariables = {
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTasksQuery = {
  syncTasks?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TasksByAuthorIDQueryVariables = {
  authorID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TasksByAuthorIDQuery = {
  tasksByAuthorID?:  {
    __typename: "ModelTaskConnection",
    items:  Array< {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email: string,
    details?: string | null,
    userSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    authorTask?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name?: string | null,
      email: string,
      details?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      name?: string | null,
      email: string,
      details?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTaskCategoryQueryVariables = {
  id: string,
};

export type GetTaskCategoryQuery = {
  getTaskCategory?:  {
    __typename: "TaskCategory",
    id: string,
    categoryId: string,
    taskId: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTaskCategoriesQueryVariables = {
  filter?: ModelTaskCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTaskCategoriesQuery = {
  listTaskCategories?:  {
    __typename: "ModelTaskCategoryConnection",
    items:  Array< {
      __typename: "TaskCategory",
      id: string,
      categoryId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTaskCategoriesQueryVariables = {
  filter?: ModelTaskCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTaskCategoriesQuery = {
  syncTaskCategories?:  {
    __typename: "ModelTaskCategoryConnection",
    items:  Array< {
      __typename: "TaskCategory",
      id: string,
      categoryId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TaskCategoriesByCategoryIdQueryVariables = {
  categoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaskCategoriesByCategoryIdQuery = {
  taskCategoriesByCategoryId?:  {
    __typename: "ModelTaskCategoryConnection",
    items:  Array< {
      __typename: "TaskCategory",
      id: string,
      categoryId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TaskCategoriesByTaskIdQueryVariables = {
  taskId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTaskCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TaskCategoriesByTaskIdQuery = {
  taskCategoriesByTaskId?:  {
    __typename: "ModelTaskCategoryConnection",
    items:  Array< {
      __typename: "TaskCategory",
      id: string,
      categoryId: string,
      taskId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateActiveTaskSubscriptionVariables = {
  filter?: ModelSubscriptionActiveTaskFilterInput | null,
};

export type OnCreateActiveTaskSubscription = {
  onCreateActiveTask?:  {
    __typename: "ActiveTask",
    id: string,
    currentTaskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateActiveTaskSubscriptionVariables = {
  filter?: ModelSubscriptionActiveTaskFilterInput | null,
};

export type OnUpdateActiveTaskSubscription = {
  onUpdateActiveTask?:  {
    __typename: "ActiveTask",
    id: string,
    currentTaskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteActiveTaskSubscriptionVariables = {
  filter?: ModelSubscriptionActiveTaskFilterInput | null,
};

export type OnDeleteActiveTaskSubscription = {
  onDeleteActiveTask?:  {
    __typename: "ActiveTask",
    id: string,
    currentTaskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSelectionSubscriptionVariables = {
  filter?: ModelSubscriptionSelectionFilterInput | null,
};

export type OnCreateSelectionSubscription = {
  onCreateSelection?:  {
    __typename: "Selection",
    id: string,
    status: SelectionStatus,
    timeStart?: string | null,
    timeFinish?: string | null,
    userID: string,
    taskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSelectionSubscriptionVariables = {
  filter?: ModelSubscriptionSelectionFilterInput | null,
};

export type OnUpdateSelectionSubscription = {
  onUpdateSelection?:  {
    __typename: "Selection",
    id: string,
    status: SelectionStatus,
    timeStart?: string | null,
    timeFinish?: string | null,
    userID: string,
    taskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSelectionSubscriptionVariables = {
  filter?: ModelSubscriptionSelectionFilterInput | null,
};

export type OnDeleteSelectionSubscription = {
  onDeleteSelection?:  {
    __typename: "Selection",
    id: string,
    status: SelectionStatus,
    timeStart?: string | null,
    timeFinish?: string | null,
    userID: string,
    taskID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    categoryTasks?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    categoryTasks?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    description?: string | null,
    categoryTasks?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnCreateTaskSubscription = {
  onCreateTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    activeTaskID: string,
    description?: string | null,
    taskSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskCategories?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    duration?: number | null,
    difficulty?: number | null,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnUpdateTaskSubscription = {
  onUpdateTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    activeTaskID: string,
    description?: string | null,
    taskSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskCategories?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    duration?: number | null,
    difficulty?: number | null,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTaskSubscriptionVariables = {
  filter?: ModelSubscriptionTaskFilterInput | null,
};

export type OnDeleteTaskSubscription = {
  onDeleteTask?:  {
    __typename: "Task",
    id: string,
    name: string,
    activeTaskID: string,
    description?: string | null,
    taskSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    taskCategories?:  {
      __typename: "ModelTaskCategoryConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    duration?: number | null,
    difficulty?: number | null,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email: string,
    details?: string | null,
    userSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    authorTask?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email: string,
    details?: string | null,
    userSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    authorTask?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    name?: string | null,
    email: string,
    details?: string | null,
    userSelections?:  {
      __typename: "ModelSelectionConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    authorTask?:  {
      __typename: "ModelTaskConnection",
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTaskCategorySubscriptionVariables = {
  filter?: ModelSubscriptionTaskCategoryFilterInput | null,
};

export type OnCreateTaskCategorySubscription = {
  onCreateTaskCategory?:  {
    __typename: "TaskCategory",
    id: string,
    categoryId: string,
    taskId: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTaskCategorySubscriptionVariables = {
  filter?: ModelSubscriptionTaskCategoryFilterInput | null,
};

export type OnUpdateTaskCategorySubscription = {
  onUpdateTaskCategory?:  {
    __typename: "TaskCategory",
    id: string,
    categoryId: string,
    taskId: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTaskCategorySubscriptionVariables = {
  filter?: ModelSubscriptionTaskCategoryFilterInput | null,
};

export type OnDeleteTaskCategorySubscription = {
  onDeleteTaskCategory?:  {
    __typename: "TaskCategory",
    id: string,
    categoryId: string,
    taskId: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    task:  {
      __typename: "Task",
      id: string,
      name: string,
      activeTaskID: string,
      description?: string | null,
      duration?: number | null,
      difficulty?: number | null,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
