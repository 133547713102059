import { Task } from '../../API';
import { useCurrentTasks } from '../../contexts/TasksContext';

export function Achievements() {
  const { tasks: completedTasks } = useCurrentTasks();
  const noOfCompletedTasks = completedTasks.length;

  function renderTask(completedTask: Task, idx: number) {
    return (
      <div key={idx} className="completedTask">
        <p>{completedTask.name + ' ★'}</p>
      </div>
    );
  }

  return (
    <div>
      <div className="banner">{noOfCompletedTasks} Tasks Completed</div>

      <div id="completedTaskList">
        {completedTasks.map((task, idx) => renderTask(task, idx))}
      </div>
    </div>
  );
}
