import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { AUTHENTICATED, CONFIGURING } from '../../types/auth';
import { useCurrentAuth } from '../AuthContext';

interface NoSessionRouteProps {
  children: ReactNode;
}

export const NoSessionRoute = ({ children }: NoSessionRouteProps) => {
  const { authStatus } = useCurrentAuth();

  if (authStatus === CONFIGURING) {
    return (
      <div className="App">
        <span>Loading...</span>
      </div>
    );
  }

  if (authStatus === AUTHENTICATED) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};
