import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { GetAllCategories } from '../../queries/category';
import { GetAllTasks } from '../../queries/task';
import { ADD_TASK_PATH } from '../../types/routing';

export function Categories() {
  const navigate = useNavigate();

  const categorySelect = () => {
    navigate(ADD_TASK_PATH);
  };

  const test = () => {
    GetAllCategories();
    GetAllTasks();
  };

  return (
    <div>
      <span>
        <Button onClick={test}> Get All Categories </Button>
        <Button onClick={categorySelect}>Cooking</Button>
        <Button onClick={categorySelect}>Sport</Button>
        <Button onClick={categorySelect}>Dance</Button>
      </span>
    </div>
  );
}
