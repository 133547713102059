import { API, graphqlOperation } from 'aws-amplify';
import {
  createCategory,
  deleteCategory,
  updateCategory
} from '../graphql/mutations';
import {
  CreateCategoryInput,
  CreateCategoryMutation,
  DeleteCategoryMutation,
  GetCategoryQuery,
  ListCategoriesQuery,
  UpdateCategoryMutation
} from '../API';
import { GraphQLQuery } from '@aws-amplify/api';
import { getCategory, listCategories } from '../graphql/queries';
import { DEVELOPMENT, ENVIRONMENT } from '../types/environment';
import { mockCategories } from '../types/mock/categories';

//////////////////////////////////////////////////////////////////
// Function name : CreateCategory
// Function goal : Create new category
// Input :
// -  Req - name    : string
// -  Opt - desc    : string | null (default: null)
// Output : data of created category
//////////////////////////////////////////////////////////////////
export async function CreateCategory(name: string, desc: string | null = null) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Creating new category');
    return;
  }
  const category: CreateCategoryInput = { name: name, description: desc };
  const newCategory: any = await API.graphql<
    GraphQLQuery<CreateCategoryMutation>
  >(graphqlOperation(createCategory, { input: category }));
  console.log('Created new category');
  console.log(newCategory.data.createCategory);

  return newCategory.data.createCategory;
}

//////////////////////////////////////////////////////////////////
// Function name : UpdateCategory
// Function goal : Update an existing category
// Input :
// -  Req - categoryId  : string
// -  Opt - name        : string | null (default: null)
// -  Opt - desc        : string | null (default: null)
// Output : data of updated category
//////////////////////////////////////////////////////////////////
export async function UpdateCategory(
  categoryId: string,
  name: string | null = null,
  desc: string | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Updating existing category');
    return;
  }
  const updatedCategory: any = await API.graphql<
    GraphQLQuery<UpdateCategoryMutation>
  >(
    graphqlOperation(updateCategory, {
      input: { id: categoryId, name: name, description: desc }
    })
  );
  console.log('Updated a category');
  console.log(updatedCategory.data.updateCategory);

  return updatedCategory.data.updateCategory;
}

//////////////////////////////////////////////////////////////////
// Function name : DeleteCategory
// Function goal : Delete an existing category
// Input :
// -  Req - categoryId  : string
// Output : data of deleted category
//////////////////////////////////////////////////////////////////
export async function DeleteCategory(categoryId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Deleting existing category');
    return;
  }
  const deletedCategory: any = await API.graphql<
    GraphQLQuery<DeleteCategoryMutation>
  >(graphqlOperation(deleteCategory, { input: { id: categoryId } }));
  console.log('Deleted a category');
  console.log(deletedCategory.data.deleteCategory);

  return deletedCategory.data.deleteCategory;
}

//////////////////////////////////////////////////////////////////
// Function name : GetAllCategory=ies
// Function goal : List all existing categories
// Input : None
// Output : list of existing categories
//////////////////////////////////////////////////////////////////
export async function GetAllCategories() {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning all categories');
    return mockCategories;
  }
  const allCategories: any = await API.graphql<
    GraphQLQuery<ListCategoriesQuery>
  >(graphqlOperation(listCategories));
  console.log('Got all categories');
  console.log(allCategories.data.listCategories);

  return allCategories.data.listCategories;
}

//////////////////////////////////////////////////////////////////
// Function name : GetCategory
// Function goal : Get an existing category
// Input :
// -  Req - categoryId  : string
// Output : data of the chosen category
//////////////////////////////////////////////////////////////////
export async function GetCategory(categoryId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning one category');
    return mockCategories[0];
  }
  const oneCategory: any = await API.graphql<GraphQLQuery<GetCategoryQuery>>(
    graphqlOperation(getCategory, { id: categoryId })
  );
  console.log('Got a category');
  console.log(oneCategory.data.getCategory);

  return oneCategory.data.getCategory;
}
