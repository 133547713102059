import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Header } from '../../desktopApp/components/Header';
import { CONFIGURING, UNAUTHENTICATED } from '../../types/auth';
import { useCurrentAuth } from '../AuthContext';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { authStatus } = useCurrentAuth();

  if (authStatus === CONFIGURING) {
    return (
      <div className="App">
        <span>Loading...</span>
      </div>
    );
  }

  if (authStatus === UNAUTHENTICATED) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <Header></Header>

      {children}
    </div>
  );
};
