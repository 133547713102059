import { User } from '../../API';

export const mockUsers: User[] = [
  {
    id: 'userid1',
    name: 'username1',
    email: 'user1@mock.com',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'User'
  },
  {
    id: 'userid2',
    name: 'username2',
    email: 'user2@mock.com',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'User'
  },
  {
    id: 'userid3',
    name: 'username3',
    email: 'user3@mock.com',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'User'
  }
];
