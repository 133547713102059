import { Avatar, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { User } from '../../API';
import { useCurrentAuth, UserAttributes } from '../../contexts/AuthContext';
import { UpdateUserProfilePicture } from '../../queries/user';
import './Colors.css';
import './Settings.css';

export function Settings() {
  const { userAttributes, saveUserAttributes, userDetails, saveUserDetails } =
    useCurrentAuth();
  const parsedUserDetails: User = userDetails as User;

  const [email, setEmail] = useState(userAttributes?.email);
  const [displayName, setDisplayName] = useState(parsedUserDetails?.name);

  const [selectedImage, setSelectedImage] = useState(undefined);
  const [areSettingsChanged, setAreSettingsChanged] = useState(false);

  // TODO: Make sure this does not update with stale data. https://github.com/jt3698/JustStart/issues/88
  useEffect(() => {
    if (selectedImage) {
      console.log('Uploading photo');
      if (!userAttributes) {
        console.error(
          'User attributes should not be null when updating profile picture'
        );
        return;
      }
      UpdateUserProfilePicture(userAttributes.id, selectedImage, userDetails)
        .then(() => {
          window.alert('Profile picture updated');
        })
        .catch(() => {
          window.alert('Upload failed');
        });
    }
  }, [userAttributes, userDetails, selectedImage]);

  const handleRemovePhoto = () => {
    // show a confirmation modal for removing photo
    console.log('Mock remove photo...');
    setSelectedImage(undefined);
  };

  const handleSaveUserSettings = () => {
    saveUserAttributes({
      ...(userAttributes as UserAttributes),
      email: email ?? ''
    });
    saveUserDetails({ ...parsedUserDetails, name: displayName });
    console.log('Mock saving user settings...');
  };

  return (
    <div className="settingsPageContainer">
      <div className="avatarContainer">
        <div className="avatar">
          {selectedImage ? (
            <img
              alt="not found"
              width={'256px'}
              src={URL.createObjectURL(selectedImage)}
            />
          ) : (
            <Avatar sx={{ width: 256, height: 256 }}>J</Avatar>
          )}
        </div>

        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          id="contained-button-file"
          onClick={(event: any) => (event.target.value = null)} // Clear input form to register changes
          onChange={(event: any) => {
            console.log(event.target.files[0]);
            setSelectedImage(event.target.files[0]);
          }}
        />
        <label htmlFor="contained-button-file">
          <div className="clickableText">
            <span>Upload Photo</span>
          </div>
        </label>

        <div className="clickableText" onClick={handleRemovePhoto}>
          <span>Remove Photo</span>
        </div>
      </div>
      <div className="settingsContainer">
        <div className="settingsHeader">Account Settings</div>
        <div className="settingsInput">
          <TextField
            variant="filled"
            label="Email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setAreSettingsChanged(true);
            }}
            sx={{
              backgroundColor: '#FFE7C1',
              width: 384
            }}
          />
        </div>
        <div className="settingsInput">
          <TextField
            variant="filled"
            label="Display Name"
            value={displayName}
            onChange={(event) => {
              setDisplayName(event.target.value);
              setAreSettingsChanged(true);
            }}
            sx={{
              backgroundColor: '#FFE7C1',
              width: 384
            }}
          />
        </div>
        {areSettingsChanged && ( // show save button if user changed anything (even if there is no net change)
          <div className="saveButtonContainer">
            <Button
              variant="contained"
              onClick={handleSaveUserSettings}
              sx={{
                backgroundColor: '#19A8BB',
                height: 32
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
