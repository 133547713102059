import AddIcon from '@mui/icons-material/Add';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Task } from '../../API';
import { useCurrentTasks } from '../../contexts/TasksContext';
import { TaskDescription } from '../../shared/components/TaskDescription';
import { CATEGORIES_PATH } from '../../types/routing';
import './Home.css';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function HomePage() {
  const navigate = useNavigate();

  const [taskDescriptionId, setTaskDescriptionId] = useState('');
  const { tasks } = useCurrentTasks();

  const addNewTask = () => {
    navigate(CATEGORIES_PATH);
  };

  const goToTaskDescription = (taskId: string) => {
    console.log(`Open task description, id: ${taskId}`);
    setTaskDescriptionId(taskId);
  };

  const handleClose = () => {
    setTaskDescriptionId('');
  };

  function renderTask(activeTask: Task, idx: number) {
    return (
      <div
        key={idx}
        className="m_activeTask"
        onClick={() => {
          goToTaskDescription(activeTask.id);
        }}
      >
        <p>{activeTask.name}</p>
      </div>
    );
  }

  return (
    <div id="m_Home">
      <div id="m_activeTaskTitle">Active Tasks</div>

      <div id="m_activeTaskList">
        {tasks.map((activeTask, idx) => renderTask(activeTask, idx))}
      </div>

      <div id="m_addTask" onClick={addNewTask}>
        <AddIcon fontSize="large" className="m_addTaskIcon" />
      </div>

      <Dialog
        open={taskDescriptionId !== ''}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <TaskDescription taskId={taskDescriptionId} handleClose={handleClose} />
      </Dialog>
    </div>
  );
}
