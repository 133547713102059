import { API, graphqlOperation } from 'aws-amplify';
import {
  createSelection,
  deleteSelection,
  updateSelection
} from '../graphql/mutations';
import {
  listSelections,
  getSelection,
  selectionsByUserID,
  selectionsByTaskID
} from '../graphql/queries';
import {
  CreateSelectionInput,
  CreateSelectionMutation,
  DeleteSelectionMutation,
  GetSelectionQuery,
  ListSelectionsQuery,
  SelectionStatus,
  SelectionsByTaskIDQuery,
  SelectionsByUserIDQuery,
  UpdateSelectionMutation
} from '../API';
import { GraphQLQuery } from '@aws-amplify/api';
import { DEVELOPMENT, ENVIRONMENT } from '../types/environment';
import { mockSelections } from '../types/mock/selections';

//////////////////////////////////////////////////////////////////
// Function name : CreateSelection
// Function goal : Create a new selection
// Input :
// -  Req - userId  : string
// -  Req - taskId  : string
// -  Req - status  : SelectionStatus
// -  Opt - start   : string | null (default: null)
// -  Opt - finish  : string | null (default: null)
// Output : data of created selection
//////////////////////////////////////////////////////////////////
export async function CreateSelection(
  userId: string,
  taskId: string,
  status: SelectionStatus,
  start: string | null = null,
  finish: string | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Creating new selection');
    return;
  }
  const selection: CreateSelectionInput = {
    userID: userId,
    taskID: taskId,
    status: status,
    timeStart: start,
    timeFinish: finish
  };
  const newSelection: any = await API.graphql<
    GraphQLQuery<CreateSelectionMutation>
  >(graphqlOperation(createSelection, { input: selection }));
  console.log('Created new Selection');
  console.log(newSelection.data.createSelection);

  return newSelection.data.createSelection;
}

//////////////////////////////////////////////////////////////////
// Function name : UpdateSelection
// Function goal : Update an existing selection
// Input :
// -  Req - selectionId : string
// -  Opt - userId      : string | null (default: null)
// -  Opt - taskId      : string | null (default: null)
// -  Opt - status      : SelectionStatus | null (default: null)
// -  Opt - start       : string | null (default: null)
// -  Opt - finish      : string | null (default: null)
// Output : data of created selection
//////////////////////////////////////////////////////////////////
export async function UpdateSelection(
  selectionId: string,
  userId: string | null = null,
  taskId: string | null = null,
  status: SelectionStatus | null = null,
  start: string | null = null,
  finish: string | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Updating existing selection');
    return;
  }
  const updatedSelection: any = await API.graphql<
    GraphQLQuery<UpdateSelectionMutation>
  >(
    graphqlOperation(updateSelection, {
      input: {
        id: selectionId,
        userID: userId,
        taskID: taskId,
        status: status,
        timeStart: start,
        timeFinish: finish
      }
    })
  );
  console.log('Updated a Selection');
  console.log(updatedSelection.data.updateSelection);

  return updatedSelection.data.updateSelection;
}

//////////////////////////////////////////////////////////////////
// Function name : DeleteSelection
// Function goal : Delete an existing selection
// Input :
// -  Req - selectionId : string
// Output : data of deleted selection
//////////////////////////////////////////////////////////////////
export async function DeleteSelection(selectionId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Deleting existing selection');
    return;
  }
  const deletedSelection: any = await API.graphql<
    GraphQLQuery<DeleteSelectionMutation>
  >(graphqlOperation(deleteSelection, { input: { id: selectionId } }));
  console.log('Deleted a Selection');
  console.log(deletedSelection.data.deleteSelection);

  return deletedSelection.data.deleteSelection;
}

//////////////////////////////////////////////////////////////////
// Function name : GetAllSelections
// Function goal : List all existing selections
// Input : None
// Output : list of existing selections
//////////////////////////////////////////////////////////////////
export async function GetAllSelections() {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning all selections');
    return mockSelections;
  }
  const allSelections: any = await API.graphql<
    GraphQLQuery<ListSelectionsQuery>
  >(graphqlOperation(listSelections));
  console.log('Got all Selections');
  console.log(allSelections.data.listSelections);

  return allSelections.data.listSelections;
}

//////////////////////////////////////////////////////////////////
// Function name : GetSelection
// Function goal : Get an existing selection
// Input :
// -  Req - selectionId : string
// Output : data of the chosen selection
//////////////////////////////////////////////////////////////////
export async function GetSelection(selectionId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning one selection');
    return mockSelections[0];
  }
  const oneSelection: any = await API.graphql<GraphQLQuery<GetSelectionQuery>>(
    graphqlOperation(getSelection, { id: selectionId })
  );
  console.log('Got a Selection');
  console.log(oneSelection.data.getSelection);

  return oneSelection.data.getSelection;
}

//////////////////////////////////////////////////////////////////
// Function name : GetSelectionsByTask
// Function goal : Get existing selections by taskId
// Input :
// -  Req - taskId : string
// -  Opt - status : SelectionStatus | null (default: null)
// Output : data of the chosen selections
//////////////////////////////////////////////////////////////////
export async function GetSelectionsByTask(
  taskId: string,
  status: SelectionStatus | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning selections by task');
    return mockSelections;
  }
  if (status === null) {
    const selectionsByTask: any = await API.graphql<
      GraphQLQuery<SelectionsByTaskIDQuery>
    >(graphqlOperation(selectionsByTaskID, { taskID: taskId }));

    console.log('Got Selections by Task');
    console.log(selectionsByTask.data.selectionsByTaskID);

    return selectionsByTask.data.selectionsByTaskID;
  } else {
    const selectionsByTask: any = await API.graphql<
      GraphQLQuery<SelectionsByTaskIDQuery>
    >(
      graphqlOperation(selectionsByTaskID, {
        taskID: taskId,
        filter: { status: { eq: status } }
      })
    );

    console.log('Got Selections by Task with status filter');
    console.log(selectionsByTask.data.selectionsByTaskID);

    return selectionsByTask.data.selectionsByTaskID;
  }
}

//////////////////////////////////////////////////////////////////
// Function name : GetSelectionsByUser
// Function goal : Get existing selections by userId
// Input :
// -  Req - userId : string
// -  Opt - status : SelectionStatus | null (default: null)
// Output : data of the chosen selections
//////////////////////////////////////////////////////////////////
export async function GetSelectionsByUser(
  userId: string,
  status: SelectionStatus | null = null
) {
  // if (ENVIRONMENT === DEVELOPMENT) {
  //     console.log("Development: Returning selections by user");
  //     return mockSelections;
  // }
  if (status === null) {
    const selectionsByUser: any = await API.graphql<
      GraphQLQuery<SelectionsByUserIDQuery>
    >(graphqlOperation(selectionsByUserID, { userID: userId }));

    console.log('Got Selections by User');
    console.log(selectionsByUser.data.selectionsByUserID);

    return selectionsByUser.data.selectionsByUserID;
  } else {
    const selectionsByUser: any = await API.graphql<
      GraphQLQuery<SelectionsByUserIDQuery>
    >(
      graphqlOperation(selectionsByUserID, {
        userID: userId,
        filter: { status: { eq: status } }
      })
    );

    console.log('Got Selections by User with status filter');
    console.log(selectionsByUser.data.selectionsByUserID);

    return selectionsByUser.data.selectionsByUserID;
  }
}
