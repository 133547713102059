import { useNavigate } from 'react-router-dom';
import './Header.css';
import { ACHIEVEMENTS_PATH, HOME_PATH } from '../../types/routing';
import { AccountMenu } from '../../shared/components/AccountMenu';

export function Header() {
  const navigate = useNavigate();

  return (
    <div id="header">
      <div id="navbar">
        <img
          id="mainlogo"
          alt="JustStart logo"
          src="juststartlogo.svg"
          onClick={() => {
            navigate(HOME_PATH);
          }}
        />

        <span
          className="option"
          onClick={() => {
            navigate(ACHIEVEMENTS_PATH);
          }}
        >
          Achievements
        </span>
      </div>
      <AccountMenu></AccountMenu>
    </div>
  );
}
