/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "01e3f0d868244810abd4557735010923",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "01e3f0d868244810abd4557735010923",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "JustStartTasks",
            "endpoint": "https://wy63gldcs8.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://aadcwvtebfbbdkq4wcwmyhrwna.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-i6v7tet2mbea5kftexfdjjh4da",
    "aws_cognito_identity_pool_id": "us-east-2:b78b87bb-c5b5-4b56-a861-d9d2467aaa38",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ReqC8wtfn",
    "aws_user_pools_web_client_id": "3q52mrugjjj7eqbs1kuh674en9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "juststart-files234533-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
