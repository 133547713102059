import { Button } from '@aws-amplify/ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Task } from '../../API';
import { HOME_PATH } from '../../types/routing';

export function TaskDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const taskId: string = id as string;

  const completeTask = (taskId: string) => {
    console.log(`mock complete task with id: ${taskId}`);
    navigate(HOME_PATH);
  };

  const MockGetTaskInfo = (taskId: string): Task => {
    return {
      __typename: 'Task',
      id: taskId,
      activeTaskID: `mock`,
      name: `Mock Task with id ${taskId}`,
      authorID: `mock`,
      createdAt: 'x',
      updatedAt: 'x',
      _version: 1,
      _lastChangedAt: 1,
      description: 'This is a mock task lololol',
      duration: 4,
      difficulty: 3
    };
  };

  const MockGetTaskInfo2 = (taskId: string): Task => {
    return {
      __typename: 'Task',
      id: taskId,
      activeTaskID: `mock`,
      name: `Mock Task with id ${taskId}`,
      authorID: `mock`,
      createdAt: 'x',
      updatedAt: 'x',
      _version: 1,
      _lastChangedAt: 1,
      description: 'This is a mock task without duration',
      difficulty: 3
    };
  };

  const getTaskInfo = (taskId: string): Task => {
    if (Math.random() > 0.5) {
      return MockGetTaskInfo(taskId);
    } else {
      return MockGetTaskInfo2(taskId);
    }
  };

  const taskInfo = getTaskInfo(taskId);

  const taskInfoRender = (
    <div>
      {taskInfo.name}
      <br />
      {taskInfo.duration ? <div>Duration: {taskInfo.duration} hours</div> : ''}
      {taskInfo.difficulty ? <div>Difficulty: {taskInfo.difficulty}</div> : ''}
      <br />
      {taskInfo.description}
    </div>
  );

  return (
    <div>
      {taskInfoRender}
      <br />
      <Button onClick={() => completeTask(taskId)}>Complete Task</Button>
    </div>
  );
}
