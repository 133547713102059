import { Category } from '../../API';

export const mockCategories: Category[] = [
  {
    id: 'categoryid1',
    name: 'Mock Category 1',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Category'
  },
  {
    id: 'categoryid2',
    name: 'Mock Category 2',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Category'
  },
  {
    id: 'categoryid3',
    name: 'Mock Category 3',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Category'
  }
];
