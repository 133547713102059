import { Button, useAuthenticator } from '@aws-amplify/ui-react';
import { useCurrentAuth } from '../../contexts/AuthContext';
import { useState } from 'react';
import { User } from '../../API';

export function UserDetails() {
  const { signOut } = useAuthenticator();

  const { userAttributes, userDetails, saveUserDetails } = useCurrentAuth();
  const parsedUserDetails = userDetails as User;
  const email = userAttributes?.email;
  const name = parsedUserDetails?.name;

  const [changeName, setChangeName] = useState('');

  // UpdateUser(userID: string, name: string, email: string, details: string){
  function uploadChange() {
    saveUserDetails({ ...parsedUserDetails, name: changeName });
  }

  return (
    <div>
      <div id="userDetails">
        <p>Email: {email}</p>
        <p>Name: {name}</p>

        <label>Change Name:</label>
        <input
          type="text"
          value={changeName}
          onChange={(e) => setChangeName(e.target.value)}
        ></input>
        <Button onClick={uploadChange}>Change Name</Button>
      </div>

      <Button onClick={signOut}>Sign Out</Button>
    </div>
  );
}
