import { NoSessionHeader } from '../components/NoSessionHeader';

export function AboutUs() {
  return (
    <div>
      <NoSessionHeader></NoSessionHeader>

      <div>About Us</div>
    </div>
  );
}
