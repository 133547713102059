import { API, graphqlOperation } from 'aws-amplify';
import { createTask, deleteTask, updateTask } from '../graphql/mutations';
import {
  CreateTaskInput,
  CreateTaskMutation,
  DeleteTaskMutation,
  GetTaskQuery,
  ListTasksQuery,
  TasksByAuthorIDQuery,
  UpdateTaskMutation
} from '../API';
import { GraphQLQuery } from '@aws-amplify/api';
import { getTask, listTasks, tasksByAuthorID } from '../graphql/queries';
import { DEVELOPMENT, ENVIRONMENT } from '../types/environment';
import { mockTasks } from '../types/mock/tasks';

//////////////////////////////////////////////////////////////////
// Function name : CreateTask
// Function goal : Create a new task
// Input :
// -  Req - name        : string
// -  Req - activeTaskID: string
// -  Req - authorID    : string
// -  Opt - desc        : string | null (default: null)
// -  Opt - dur         : number | null (default: null)
// -  Opt - diff        : number | null (default: null)
// Output : data of created task
//////////////////////////////////////////////////////////////////
export async function CreateTask(
  name: string,
  activeTaskId: string,
  authorId: string,
  desc: string | null = null,
  dur: number | null = null,
  diff: number | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Creating new task');
    return;
  }
  const task: CreateTaskInput = {
    name: name,
    activeTaskID: activeTaskId,
    description: desc,
    duration: dur,
    difficulty: diff,
    authorID: authorId
  };
  const newTask: any = await API.graphql<GraphQLQuery<CreateTaskMutation>>(
    graphqlOperation(createTask, { input: task })
  );
  console.log('Created new Task');
  console.log(newTask.data.createTask);

  return newTask.data.createTask;
}

//////////////////////////////////////////////////////////////////
// Function name : UpdateTask
// Function goal : Update an existing task
// Input :
// -  Req - taskID      : string
// -  Req - activeTaskID: string
// -  Req - authorID    : string
// -  Opt - name        : string | null (default: null)
// -  Opt - desc        : string | null (default: null)
// -  Opt - dur         : number | null (default: null)
// -  Opt - diff        : number | null (default: null)
// Output : data of updated task
//////////////////////////////////////////////////////////////////
export async function UpdateTask(
  taskId: string,
  activeTaskId: string,
  authorId: string,
  name: string | null = null,
  desc: string | null = null,
  dur: number | null = null,
  diff: number | null = null
) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Updating existing task');
    return;
  }
  const updatedTask: any = await API.graphql<GraphQLQuery<UpdateTaskMutation>>(
    graphqlOperation(updateTask, {
      input: {
        id: taskId,
        name: name,
        activeTaskID: activeTaskId,
        description: desc,
        duration: dur,
        difficulty: diff,
        authorID: authorId
      }
    })
  );
  console.log('Updated a Task');
  console.log(updatedTask.data.updateTask);

  return updatedTask.data.updateTask;
}

//////////////////////////////////////////////////////////////////
// Function name : DeleteTask
// Function goal : Delete an existing task
// Input :
// -  Req - taskID  : string
// Output : data of deleted task
//////////////////////////////////////////////////////////////////
export async function DeleteTask(taskId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Deleting existing task');
    return;
  }
  const deletedTask: any = await API.graphql<GraphQLQuery<DeleteTaskMutation>>(
    graphqlOperation(deleteTask, { input: { id: taskId } })
  );
  console.log('Deleted a Task');
  console.log(deletedTask.data.deleteTask);

  return deletedTask.data.deleteTask;
}

//////////////////////////////////////////////////////////////////
// Function name : GetAllTasks
// Function goal : List all existing tasks
// Input : None
// Output : list of existing tasks
//////////////////////////////////////////////////////////////////
export async function GetAllTasks() {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning all tasks');
    return mockTasks;
  }
  const allTasks: any = await API.graphql<GraphQLQuery<ListTasksQuery>>(
    graphqlOperation(listTasks)
  );
  console.log('Got all Tasks');
  console.log(allTasks.data.listTasks);

  return allTasks.data.listTasks;
}

//////////////////////////////////////////////////////////////////
// Function name : GetTask
// Function goal : Get an existing task
// Input :
// -  Req - taskId  : string
// Output : data of the chosen task
//////////////////////////////////////////////////////////////////
export async function GetTask(taskId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning one task');
    return mockTasks[0];
  }
  console.log('Getting task ', taskId);
  const oneTask: any = await API.graphql<GraphQLQuery<GetTaskQuery>>(
    graphqlOperation(getTask, { id: taskId })
  );
  console.log('Got a Task');
  console.log(oneTask.data.getTask);

  return oneTask.data.getTask;
}

//////////////////////////////////////////////////////////////////
// Function name : GetTaskByAuthor
// Function goal : Get an existing task
// Input :
// -  Req - taskID  : string
// Output : data of the chosen task
//////////////////////////////////////////////////////////////////
export async function GetTaskByAuthor(authorId: string) {
  if (ENVIRONMENT === DEVELOPMENT) {
    console.log('Development: Returning tasks with the same author');
    return mockTasks;
  }
  const tasksByAuthor: any = await API.graphql<
    GraphQLQuery<TasksByAuthorIDQuery>
  >(graphqlOperation(tasksByAuthorID, { authorID: authorId }));
  console.log('Got all tasks by the same author');
  console.log(tasksByAuthor.data.tasksByAuthorID);

  return tasksByAuthor.data.tasksByAuthorID;
}
