import { Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { Task } from '../../API';
import { HOME_PATH } from '../../types/routing';
import { useCurrentTasks } from '../../contexts/TasksContext';
import { API } from 'aws-amplify';

export function AddTaskPage() {
  const navigate = useNavigate();
  const { tasks, saveTasks } = useCurrentTasks();

  /* 
        TODO: All other API components use IDs instead of objects themselves to pass information.
        E.g. ChooseCategories uses "categoryID: string" instead of "newCategory: Category".
        AddTaskPage currently uses "newTask: Task" instead of "taskID: string".
    */

  const MockAddTask = (newTask: Task) => {
    console.log(`mock add task from task id ${newTask.id}`);

    const myInit = {
      queryStringParameters: {
        user: 'me',
        loves: 'adding new tasks'
      }
    };
    API.get('JustStartTasks', '/getTaskOptions', myInit).then((response) => {
      console.log(response);
    });

    saveTasks([...tasks, newTask]);
  };

  const taskSelect = (newTask: Task) => {
    MockAddTask(newTask);
    navigate(HOME_PATH);
  };

  const MockGetTasks = (): Task[] => {
    const mockTasks: Task[] = [
      {
        id: 'taskid1',
        name: 'Test Task 1',
        activeTaskID: '0',
        authorID: 'meow',
        createdAt: 'x',
        updatedAt: 'x',
        _version: 1,
        _lastChangedAt: 1,
        __typename: 'Task'
      },
      {
        id: 'taskid2',
        name: 'Test Task 2',
        activeTaskID: '1',
        authorID: 'meow',
        createdAt: 'x',
        updatedAt: 'x',
        _version: 1,
        _lastChangedAt: 1,
        __typename: 'Task'
      },
      {
        id: 'taskid3',
        name: 'Test Task 3',
        activeTaskID: '2',
        authorID: 'meow',
        createdAt: 'x',
        updatedAt: 'x',
        _version: 1,
        _lastChangedAt: 1,
        __typename: 'Task'
      }
    ];
    return mockTasks;
  };

  const listOfTasks = MockGetTasks().map((task, idx) => (
    <Button
      key={idx}
      onClick={() => {
        taskSelect(task);
      }}
    >
      {task.name}
    </Button>
  ));

  return (
    <div>
      <span>{listOfTasks}</span>
    </div>
  );
}
