import { useLocation, useNavigate } from 'react-router-dom';
import './Footer.css';
import { USER_PATH, ACHIEVEMENTS_PATH, HOME_PATH } from '../../types/routing';
import HomeIcon from '@mui/icons-material/Home';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';

export function FooterMobile() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="m_footerContainer">
      <HomeIcon
        fontSize="large"
        className="m_footerIcon"
        id={location.pathname == HOME_PATH ? 'm_selectedFooterIcon' : ''}
        onClick={() => {
          navigate(HOME_PATH);
        }}
      />
      <StarIcon
        fontSize="large"
        className="m_footerIcon"
        id={
          location.pathname == ACHIEVEMENTS_PATH ? 'm_selectedFooterIcon' : ''
        }
        onClick={() => {
          navigate(ACHIEVEMENTS_PATH);
        }}
      />
      <PersonIcon
        fontSize="large"
        className="m_footerIcon"
        id={location.pathname == USER_PATH ? 'm_selectedFooterIcon' : ''}
        onClick={() => {
          navigate(USER_PATH);
        }}
      />
    </div>
  );
}
