import { useNavigate } from 'react-router-dom';
import './Header.css';
import { ABOUT_US_PATH, HOME_PATH } from '../../types/routing';
import { LoginButton } from '../../shared/components/LoginButton';

export function NoSessionHeader() {
  const navigate = useNavigate();

  return (
    <div id="header">
      <div id="navbar">
        <img
          id="mainlogo"
          alt="JustStart logo"
          src="juststartlogo.svg"
          onClick={() => {
            navigate(HOME_PATH);
          }}
        />

        <span
          className="option"
          onClick={() => {
            navigate(ABOUT_US_PATH);
          }}
        >
          Get Inspired
        </span>
      </div>
      <LoginButton></LoginButton>
    </div>
  );
}
