import { mobileCheck } from './mobile';

import DesktopApp from './desktopApp/App';
import MobileApp from './mobileApp/App';

function App() {
  return mobileCheck() ? <MobileApp></MobileApp> : <DesktopApp></DesktopApp>;
}

export default App;
