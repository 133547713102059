import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { GetTask } from '../../queries/task';
import * as TaskTypes from '../../types/task';
import './TaskDescription.css';
import taskDesc from './tempdesc.json';

interface TaskDescriptionProps {
  taskId: string;
  handleClose: () => void;
}

export function TaskDescription({ taskId, handleClose }: TaskDescriptionProps) {
  const [title, setTitle] = useState('Loading title...');
  const [description, setDescription] = useState<TaskTypes.TaskDescription>([
    {
      id: 1,
      type: TaskTypes.DescriptionComponentType.Paragraph,
      content: 'Loading content...'
    }
  ]);
  useEffect(() => {
    if (taskId) {
      GetTask(taskId)
        .then((task) => {
          setTitle(task.name);
          if (task.description) {
            setDescription(JSON.parse(task.description)?.description);
          }
        })
        .catch((err) => {
          console.error('Error loading content...', err);
          setTitle('Managing Inventory with Python');
          setDescription(taskDesc.description as TaskTypes.TaskDescription);
        });
    }
  }, [taskId]);

  const renderDescriptionComponent = (
    component: TaskTypes.TaskDescriptionComponent,
    idx: number
  ) => {
    if (component.type === TaskTypes.DescriptionComponentType.Paragraph) {
      return (
        <pre className="taskContentPar" key={idx}>
          {component.content}
        </pre>
      );
    } else if (component.type === TaskTypes.DescriptionComponentType.Code) {
      return (
        <span key={idx}>
          <p className="taskContentLang">{component.lang}</p>
          <pre className="taskContentCode">{component.content}</pre>
        </span>
      );
    }
  };
  return (
    <div className="taskModal">
      <div className="closeIconContainer">
        <CloseIcon onClick={handleClose} className="closeIcon" />
      </div>
      <div className="taskDescription">
        <div className="taskTitle">{title}</div>

        <div className="taskContent">
          {description.map((descriptionComponent, idx) =>
            renderDescriptionComponent(descriptionComponent, idx)
          )}
        </div>
      </div>

      <div className="taskCompletedButton">Task Completed</div>
    </div>
  );
}
