import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import './AccountMenu.css';
import { LOGIN_REGISTER_PATH } from '../../types/routing';
import './LoginButton.css';

export function LoginButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(LOGIN_REGISTER_PATH);
  };

  return (
    <React.Fragment>
      <Box onClick={handleClick} className={'login_button'}>
        <Tooltip title="Login">
          <div>Login</div>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
}
