import { Help, Person } from '@mui/icons-material';
import Settings from '@mui/icons-material/Settings';
import { ThemeProvider, createTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_PATH, USER_PATH } from '../../types/routing';
import './AccountMenu.css';

export function AccountMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleClose();
    navigate(USER_PATH);
  };

  const handleSettingsClick = () => {
    handleClose();
    navigate(SETTINGS_PATH);
  };

  const theme = createTheme({
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: '#FFE7C1'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            paddingRight: '36px'
          }
        }
      }
    }
  });

  // for the popup styling
  const menuPaperSlotProps = {
    elevation: 0,
    sx: {
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 26,
        width: 10,
        height: 10,
        bgcolor: '#FFE7C1',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0
      }
    }
  };

  return (
    <React.Fragment>
      <Box onClick={handleClick} className={'accountSettings'}>
        <Tooltip title="Account settings">
          <div>
            <span id="usernameText">Jt3698</span>
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>J</Avatar>
            </IconButton>
          </div>
        </Tooltip>
      </Box>
      <ThemeProvider theme={theme}>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{ paper: menuPaperSlotProps }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleProfileClick}>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={handleSettingsClick}>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            Help
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </React.Fragment>
  );
}
