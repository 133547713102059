/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getActiveTask = /* GraphQL */ `query GetActiveTask($id: ID!) {
  getActiveTask(id: $id) {
    id
    currentTaskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActiveTaskQueryVariables,
  APITypes.GetActiveTaskQuery
>;
export const listActiveTasks = /* GraphQL */ `query ListActiveTasks(
  $filter: ModelActiveTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listActiveTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      currentTaskID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActiveTasksQueryVariables,
  APITypes.ListActiveTasksQuery
>;
export const syncActiveTasks = /* GraphQL */ `query SyncActiveTasks(
  $filter: ModelActiveTaskFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncActiveTasks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      currentTaskID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncActiveTasksQueryVariables,
  APITypes.SyncActiveTasksQuery
>;
export const getSelection = /* GraphQL */ `query GetSelection($id: ID!) {
  getSelection(id: $id) {
    id
    status
    timeStart
    timeFinish
    userID
    taskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSelectionQueryVariables,
  APITypes.GetSelectionQuery
>;
export const listSelections = /* GraphQL */ `query ListSelections(
  $filter: ModelSelectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listSelections(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      timeStart
      timeFinish
      userID
      taskID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSelectionsQueryVariables,
  APITypes.ListSelectionsQuery
>;
export const syncSelections = /* GraphQL */ `query SyncSelections(
  $filter: ModelSelectionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSelections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      status
      timeStart
      timeFinish
      userID
      taskID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSelectionsQueryVariables,
  APITypes.SyncSelectionsQuery
>;
export const selectionsByUserID = /* GraphQL */ `query SelectionsByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSelectionFilterInput
  $limit: Int
  $nextToken: String
) {
  selectionsByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      timeStart
      timeFinish
      userID
      taskID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SelectionsByUserIDQueryVariables,
  APITypes.SelectionsByUserIDQuery
>;
export const selectionsByTaskID = /* GraphQL */ `query SelectionsByTaskID(
  $taskID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelSelectionFilterInput
  $limit: Int
  $nextToken: String
) {
  selectionsByTaskID(
    taskID: $taskID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      timeStart
      timeFinish
      userID
      taskID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SelectionsByTaskIDQueryVariables,
  APITypes.SelectionsByTaskIDQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    description
    categoryTasks {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const syncCategories = /* GraphQL */ `query SyncCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCategoriesQueryVariables,
  APITypes.SyncCategoriesQuery
>;
export const getTask = /* GraphQL */ `query GetTask($id: ID!) {
  getTask(id: $id) {
    id
    name
    activeTaskID
    description
    taskSelections {
      nextToken
      startedAt
      __typename
    }
    taskCategories {
      nextToken
      startedAt
      __typename
    }
    duration
    difficulty
    authorID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTaskQueryVariables, APITypes.GetTaskQuery>;
export const listTasks = /* GraphQL */ `query ListTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      activeTaskID
      description
      duration
      difficulty
      authorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTasksQueryVariables, APITypes.ListTasksQuery>;
export const syncTasks = /* GraphQL */ `query SyncTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTasks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      activeTaskID
      description
      duration
      difficulty
      authorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncTasksQueryVariables, APITypes.SyncTasksQuery>;
export const tasksByAuthorID = /* GraphQL */ `query TasksByAuthorID(
  $authorID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  tasksByAuthorID(
    authorID: $authorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      activeTaskID
      description
      duration
      difficulty
      authorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TasksByAuthorIDQueryVariables,
  APITypes.TasksByAuthorIDQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    name
    email
    details
    userSelections {
      nextToken
      startedAt
      __typename
    }
    authorTask {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      email
      details
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getTaskCategory = /* GraphQL */ `query GetTaskCategory($id: ID!) {
  getTaskCategory(id: $id) {
    id
    categoryId
    taskId
    category {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      activeTaskID
      description
      duration
      difficulty
      authorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTaskCategoryQueryVariables,
  APITypes.GetTaskCategoryQuery
>;
export const listTaskCategories = /* GraphQL */ `query ListTaskCategories(
  $filter: ModelTaskCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaskCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      categoryId
      taskId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTaskCategoriesQueryVariables,
  APITypes.ListTaskCategoriesQuery
>;
export const syncTaskCategories = /* GraphQL */ `query SyncTaskCategories(
  $filter: ModelTaskCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTaskCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      categoryId
      taskId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTaskCategoriesQueryVariables,
  APITypes.SyncTaskCategoriesQuery
>;
export const taskCategoriesByCategoryId = /* GraphQL */ `query TaskCategoriesByCategoryId(
  $categoryId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaskCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  taskCategoriesByCategoryId(
    categoryId: $categoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      categoryId
      taskId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaskCategoriesByCategoryIdQueryVariables,
  APITypes.TaskCategoriesByCategoryIdQuery
>;
export const taskCategoriesByTaskId = /* GraphQL */ `query TaskCategoriesByTaskId(
  $taskId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTaskCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  taskCategoriesByTaskId(
    taskId: $taskId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      categoryId
      taskId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TaskCategoriesByTaskIdQueryVariables,
  APITypes.TaskCategoriesByTaskIdQuery
>;
