import { Task } from '../../API';

export const mockTasks: Task[] = [
  {
    id: 'taskid1',
    activeTaskID: 'activetaskid1',
    name: 'Mock Task 1',
    authorID: 'userid1',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Task'
  },
  {
    id: 'taskid2',
    activeTaskID: 'activetaskid2',
    name: 'Mock Task 2',
    authorID: 'userid1',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Task'
  },
  {
    id: 'taskid3',
    activeTaskID: 'activetaskid3',
    name: 'Mock Task 3',
    authorID: 'userid2',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Task'
  },
  {
    id: 'taskid4',
    activeTaskID: 'activetaskid1',
    name: 'Mock Task 1 version 2',
    authorID: 'userid1',
    createdAt: 'x',
    updatedAt: 'x',
    _version: 1,
    _lastChangedAt: 1,
    __typename: 'Task'
  }
];
