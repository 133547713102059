import { useNavigate } from 'react-router-dom';
import './Header.css';
import { HOME_PATH } from '../../types/routing';

export function HeaderMobile() {
  const navigate = useNavigate();

  return (
    <div id="m_header">
      <div id="m_navbar">
        <img
          id="m_mainlogo"
          alt="JustStart logo"
          src="juststartlogo.svg"
          onClick={() => {
            navigate(HOME_PATH);
          }}
        />

        {/* <span 
                    className="option" 
                    onClick={() => {navigate(ACHIEVEMENTS_PATH)}}>
                    Achievements
                </span>
                <span 
                    className="option" 
                    onClick={() => {navigate(ABOUT_US_PATH)}}>
                    Get Inspired
                </span> */}
      </div>
    </div>
  );
}
