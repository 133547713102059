import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { GetSelectionsByUser } from '../queries/selection';
import { GetTask } from '../queries/task';
import { SelectionStatus, Task } from '../API';
import { useCurrentAuth } from './AuthContext';

export interface TasksContextType {
  tasks: Task[];
  saveTasks: (targetTasks: Task[]) => void;

  isLoading: boolean;
  saveIsLoading: (targetIsLoading: boolean) => void;
}

export const TasksContext = createContext<TasksContextType | null>(null);

interface TasksProviderProps {
  children: ReactNode;
}

export function TasksProvider({ children }: TasksProviderProps) {
  const { userAttributes } = useCurrentAuth();
  const userID = userAttributes?.id;

  const [tasks, setTasks] = useState<Task[]>([]);
  const saveTasks = (targetTasks: Task[]) => setTasks(targetTasks);

  const [isLoading, setIsLoading] = useState(false);
  const saveIsLoading = (targetIsLoading: boolean) =>
    setIsLoading(targetIsLoading);

  useEffect(() => {
    if (userID) {
      saveIsLoading(true);
      GetSelectionsByUser(userID, SelectionStatus.STARTED)
        .then((selections) => {
          const promiseTasks = selections.items.map((selection: any) => {
            return GetTask(selection.taskID);
          });
          Promise.all(promiseTasks).then((tasks) => {
            saveTasks(tasks);
            saveIsLoading(false);
          });
        })
        .catch(() => {
          saveIsLoading(false);
        });
    }
  }, [userID]);

  return (
    <TasksContext.Provider
      value={{
        tasks,
        saveTasks,
        isLoading,
        saveIsLoading
      }}
    >
      {children}
    </TasksContext.Provider>
  );
}

export const useCurrentTasks = () => {
  const currentTasksContext = useContext(TasksContext);

  if (!currentTasksContext) {
    throw new Error('useCurrentTasks has to be used within <TasksProvider>');
  }

  return currentTasksContext;
};
