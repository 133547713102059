/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createActiveTask = /* GraphQL */ `mutation CreateActiveTask(
  $input: CreateActiveTaskInput!
  $condition: ModelActiveTaskConditionInput
) {
  createActiveTask(input: $input, condition: $condition) {
    id
    currentTaskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateActiveTaskMutationVariables,
  APITypes.CreateActiveTaskMutation
>;
export const updateActiveTask = /* GraphQL */ `mutation UpdateActiveTask(
  $input: UpdateActiveTaskInput!
  $condition: ModelActiveTaskConditionInput
) {
  updateActiveTask(input: $input, condition: $condition) {
    id
    currentTaskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateActiveTaskMutationVariables,
  APITypes.UpdateActiveTaskMutation
>;
export const deleteActiveTask = /* GraphQL */ `mutation DeleteActiveTask(
  $input: DeleteActiveTaskInput!
  $condition: ModelActiveTaskConditionInput
) {
  deleteActiveTask(input: $input, condition: $condition) {
    id
    currentTaskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteActiveTaskMutationVariables,
  APITypes.DeleteActiveTaskMutation
>;
export const createSelection = /* GraphQL */ `mutation CreateSelection(
  $input: CreateSelectionInput!
  $condition: ModelSelectionConditionInput
) {
  createSelection(input: $input, condition: $condition) {
    id
    status
    timeStart
    timeFinish
    userID
    taskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSelectionMutationVariables,
  APITypes.CreateSelectionMutation
>;
export const updateSelection = /* GraphQL */ `mutation UpdateSelection(
  $input: UpdateSelectionInput!
  $condition: ModelSelectionConditionInput
) {
  updateSelection(input: $input, condition: $condition) {
    id
    status
    timeStart
    timeFinish
    userID
    taskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSelectionMutationVariables,
  APITypes.UpdateSelectionMutation
>;
export const deleteSelection = /* GraphQL */ `mutation DeleteSelection(
  $input: DeleteSelectionInput!
  $condition: ModelSelectionConditionInput
) {
  deleteSelection(input: $input, condition: $condition) {
    id
    status
    timeStart
    timeFinish
    userID
    taskID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSelectionMutationVariables,
  APITypes.DeleteSelectionMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    name
    description
    categoryTasks {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    name
    description
    categoryTasks {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    name
    description
    categoryTasks {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createTask = /* GraphQL */ `mutation CreateTask(
  $input: CreateTaskInput!
  $condition: ModelTaskConditionInput
) {
  createTask(input: $input, condition: $condition) {
    id
    name
    activeTaskID
    description
    taskSelections {
      nextToken
      startedAt
      __typename
    }
    taskCategories {
      nextToken
      startedAt
      __typename
    }
    duration
    difficulty
    authorID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskMutationVariables,
  APITypes.CreateTaskMutation
>;
export const updateTask = /* GraphQL */ `mutation UpdateTask(
  $input: UpdateTaskInput!
  $condition: ModelTaskConditionInput
) {
  updateTask(input: $input, condition: $condition) {
    id
    name
    activeTaskID
    description
    taskSelections {
      nextToken
      startedAt
      __typename
    }
    taskCategories {
      nextToken
      startedAt
      __typename
    }
    duration
    difficulty
    authorID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskMutationVariables,
  APITypes.UpdateTaskMutation
>;
export const deleteTask = /* GraphQL */ `mutation DeleteTask(
  $input: DeleteTaskInput!
  $condition: ModelTaskConditionInput
) {
  deleteTask(input: $input, condition: $condition) {
    id
    name
    activeTaskID
    description
    taskSelections {
      nextToken
      startedAt
      __typename
    }
    taskCategories {
      nextToken
      startedAt
      __typename
    }
    duration
    difficulty
    authorID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskMutationVariables,
  APITypes.DeleteTaskMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    email
    details
    userSelections {
      nextToken
      startedAt
      __typename
    }
    authorTask {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    email
    details
    userSelections {
      nextToken
      startedAt
      __typename
    }
    authorTask {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    email
    details
    userSelections {
      nextToken
      startedAt
      __typename
    }
    authorTask {
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createTaskCategory = /* GraphQL */ `mutation CreateTaskCategory(
  $input: CreateTaskCategoryInput!
  $condition: ModelTaskCategoryConditionInput
) {
  createTaskCategory(input: $input, condition: $condition) {
    id
    categoryId
    taskId
    category {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      activeTaskID
      description
      duration
      difficulty
      authorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskCategoryMutationVariables,
  APITypes.CreateTaskCategoryMutation
>;
export const updateTaskCategory = /* GraphQL */ `mutation UpdateTaskCategory(
  $input: UpdateTaskCategoryInput!
  $condition: ModelTaskCategoryConditionInput
) {
  updateTaskCategory(input: $input, condition: $condition) {
    id
    categoryId
    taskId
    category {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      activeTaskID
      description
      duration
      difficulty
      authorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskCategoryMutationVariables,
  APITypes.UpdateTaskCategoryMutation
>;
export const deleteTaskCategory = /* GraphQL */ `mutation DeleteTaskCategory(
  $input: DeleteTaskCategoryInput!
  $condition: ModelTaskCategoryConditionInput
) {
  deleteTaskCategory(input: $input, condition: $condition) {
    id
    categoryId
    taskId
    category {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      activeTaskID
      description
      duration
      difficulty
      authorID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskCategoryMutationVariables,
  APITypes.DeleteTaskCategoryMutation
>;
