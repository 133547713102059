export enum DescriptionComponentType {
  Paragraph = 'par',
  Code = 'code',
  Image = 'img',
  Audio = 'aud',
  Video = 'vid'
}

export enum CodeLanguage {
  Python = 'python',
  CPP = 'cpp',
  Java = 'java'
}

export interface TaskDescriptionComponent {
  id: number;
  type: DescriptionComponentType;
  lang?: CodeLanguage; // should be present if type is 'code'
  content: string;
}

export type TaskDescription = TaskDescriptionComponent[];
