import '@aws-amplify/ui-react/styles.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

import { Route, Routes } from 'react-router-dom';

import { AuthProvider } from '../contexts/AuthContext';
import { Categories } from './pages/Categories';
import { AddTaskPage } from './pages/AddTaskPage';

import {
  ABOUT_US_PATH,
  ACHIEVEMENTS_PATH,
  ADD_TASK_PATH,
  CATEGORIES_PATH,
  HOME_PATH,
  LOGIN_REGISTER_PATH,
  SETTINGS_PATH,
  TASK_PATH,
  USER_PATH
} from '../types/routing';

import { AboutUs } from './pages/AboutUs';
import { Achievements } from './pages/Achievements';
import { HomePage } from './pages/Home';
import { LoginRegisterPage } from './pages/LoginRegister';
import { Settings } from './pages/Settings';
import { TaskDetails } from './pages/TaskDetails';
import { UserDetails } from './pages/UserDetails';

import { TasksProvider } from '../contexts/TasksContext';
import { NoSessionRoute } from '../contexts/routing/NoSessionRoute';
import { ProtectedRoute } from '../contexts/routing/ProtectedRoute';

function DesktopApp() {
  return (
    <div className="App">
      <AuthProvider>
        <TasksProvider>
          <Routes>
            <Route
              index
              element={
                <NoSessionRoute>
                  <AboutUs />
                </NoSessionRoute>
              }
            />
            <Route
              path={ABOUT_US_PATH}
              element={
                <NoSessionRoute>
                  <AboutUs />
                </NoSessionRoute>
              }
            />
            <Route
              path={LOGIN_REGISTER_PATH}
              element={
                <NoSessionRoute>
                  <LoginRegisterPage />
                </NoSessionRoute>
              }
            />

            <Route
              path={HOME_PATH + '/:currentTaskId?'}
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ACHIEVEMENTS_PATH}
              element={
                <ProtectedRoute>
                  <Achievements />
                </ProtectedRoute>
              }
            />
            <Route
              path={USER_PATH}
              element={
                <ProtectedRoute>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path={CATEGORIES_PATH}
              element={
                <ProtectedRoute>
                  <Categories />
                </ProtectedRoute>
              }
            />
            <Route
              path={ADD_TASK_PATH}
              element={
                <ProtectedRoute>
                  <AddTaskPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={TASK_PATH}
              element={
                <ProtectedRoute>
                  <TaskDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path={SETTINGS_PATH}
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
          </Routes>
        </TasksProvider>
      </AuthProvider>
    </div>
  );
}

export default DesktopApp;
